<template>
  <div class="container">
    <div class="area-content mb8 pt12 pb12 pl15 pr15 bgf">
      <CustomerTitle class="mb16" title="基本信息" />
      <div class="centent-cell flex flex-align-center txt-line-1 mb12">
        <span class="label flex-s0">yz_openid:</span>
        <span>{{ reacData.userInfo.yz_openid || '--' }}</span>
      </div>
      <div class="centent-cell flex flex-align-center txt-line-1 mb12">
        <span class="label flex-s0">手机号:</span>
        <span>{{ reacData.userInfo.mobile || '--' }}</span>
      </div>
      <div class="centent-cell flex flex-align-center txt-line-1 mb12">
        <span class="label flex-s0">创建时间:</span>
        <span>
          {{ reacData.userInfo.create_time || '--' }}
        </span>
      </div>
      <div class="centent-cell flex flex-align-center txt-line-1">
        <span class="label flex-s0">更新时间:</span>
        <span>
          {{ reacData.userInfo.update_time || '--' }}
        </span>
      </div>
    </div>
    <div class="area-content pt12 bgf pbs">
      <CustomerTitle class="mb10 pl15 pr15" title="订单信息" />
      <van-tabs
        class="order-statu-tabs"
        v-model="reacData.selectedStatu"
        @click="onSelectStatu"
      >
        <van-tab v-for="(statu, index) in reacData.orderList" :key="statu.key">
          <template #title>
            <div
              class="order-statu-tab flex flex-align-center relative"
              :class="{
                'txt-blue': statu.key === reacData.selectedStatu
              }"
              :style="{
                marginRight: index === reacData.orderList.length - 1 ? '3px' : 0
              }"
            >
              {{ statu.value }}
              <div
                v-if="statu.key === reacData.selectedStatu"
                class="active-border"
              />
            </div>
          </template>
          <van-list
            v-model:loading="statu.loading"
            :finished="statu.isFinished"
            :immediate-check="false"
            @load="getOrderList"
          >
            <template #finished>
              <div
                class="finished-content flex flex-justify-center flex-align-center"
                :class="{
                  'empty-list': !statu.list?.length
                }"
              >
                {{ statu.list?.length ? '没有更多了' : '暂无订单信息' }}
              </div>
            </template>
            <div
              v-for="orderItem in statu.list"
              :key="orderItem.id"
              class="order-item-content pt12 pb12 pl15 pr15 border-bottom"
            >
              <div class="flex flex-align-center">
                <div class="flex-1 txt-line-1">
                  <span class="label color-opacity-65">订单号:</span>
                  {{ orderItem.order_tid }}
                </div>
                <span
                  class="flex-s0 order-statu"
                  :class="{
                    'finish-color':
                      orderItem.order_status === 'TRADE_SUCCESS' ||
                      orderItem.order_status === 'TRADE_CLOSED'
                  }"
                >
                  {{ orderItem.order_status_str }}
                </span>
              </div>
              <div class="order-time fs12">
                {{ orderItem.create_time }}
              </div>
              <div
                class="goods-content border-bottom flex flex-align-center pt12 pb12"
                v-for="(goods, index) in orderItem.detail"
                :key="index"
              >
                <img class="goods-image mr8 flex-s0" :src="goods.pic_path" />
                <div class="flex-1">
                  <div
                    class="goods-detail-line flex flex-align-center flex-justify-between fw500"
                  >
                    <div class="flex-1 txt-line-1">
                      {{ goods.title }}
                    </div>
                    <div class="flex-s0">
                      <span class="goods-sum-icon">¥</span>
                      <span>{{ goods.total_fee }}</span>
                    </div>
                  </div>
                  <div
                    class="flex flex-align-center flex-justify-between color-opacity-65"
                  >
                    <div class="flex-1 txt-line-1">
                      规格: {{ goods.sku_properties_name || '--' }}
                    </div>
                    <div class="flex-s0">*{{ goods.num }}</div>
                  </div>
                </div>
              </div>
              <div class="mt12">
                <span class="label color-opacity-65">实收金额:</span>
                {{ orderItem.pay_payment }}
              </div>
              <div class="mt12">
                <span class="label color-opacity-65">收货人:</span>
                {{ orderItem.buyer_fans_nickname }}
                {{ orderItem.buyer_buyer_phone }}
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'
import CustomerTitle from '@/components/customerTitle/index.vue'
import {
  getDetailUserInfo,
  getYouzanOrderList
} from '@/service/customer.service.js'
import { defaultAvatar, sourceEnum, clientStatuEnum } from '@/utils/constants'
import { orderStatuEnum } from '../portrait'

const props = defineProps({
  // 企微类型主体信息
  dataSource: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: () => false
  }
})
const reacData = reactive({
  pageSize: 10,
  userInfo: {}, // 客户详情
  orderList: orderStatuEnum, // 订单列表
  selectedStatu: 'all' // 当前选中的订单状态
})

onMounted(() => {
  getUserInfo()
  onSelectStatu(0)
})

// 切换订单状态
const onSelectStatu = (index) => {
  const { orderList } = reacData
  reacData.selectedStatu = orderStatuEnum[index]['key']
  if (!orderList[index].list) {
    getOrderList()
  }
}
// 获取客户详情
const getUserInfo = async () => {
  try {
    const { subject_data_id } = props.dataSource
    const res = await getDetailUserInfo(subject_data_id)

    if (res.code === 200) {
      reacData.userInfo = res.data.info
    }
  } catch (error) {
    console.log(error)
  }
}
// 获取有赞订单信息
const getOrderList = async () => {
  try {
    const { subject_data_id } = props.dataSource
    const { pageSize, orderList, selectedStatu } = reacData
    let currentPage = 0

    const index = orderList.findIndex((item) => item.key === selectedStatu)
    const list = orderList[index].list || []

    if (list.length > 0) {
      currentPage = Math.ceil(list.length / pageSize)
    }

    const body = {
      subject_data_id,
      limit: pageSize,
      page: currentPage + 1
    }
    if (selectedStatu !== 'all') body.status = selectedStatu

    const { data } = await getYouzanOrderList(body)
    orderList[index].loading = false

    if (data.length > 0) {
      orderList[index].list = [...list, ...data]
    } else {
      orderList[index].isFinished = true
    }
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="less" type="text/less" scoped>
.container {
  height: 100%;
  overflow: scroll;
}
.active-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(23, 115, 250, 0) 0%,
    #1773fa 48%,
    rgba(63, 181, 255, 0) 100%
  );
}
.border-bottom {
  border-bottom: 1px solid #edeef2;
}
.order-statu-tabs {
  :deep(.van-tab) {
    flex: none;
    padding: 0 12px;
    height: 32px;
    font-weight: 400 !important;
    color: #171717 !important;

    .van-tab__text {
      height: 100%;
    }
  }
  :deep(.van-tabs__content) {
    height: auto !important;
    overflow: initial;
  }

  .finished-content {
    height: 50px;
  }
  .empty-list {
    height: 100px;
  }
  .order-statu-tab {
    height: 100%;
  }
  .order-statu-tab:first-child {
    margin-left: 3px;
  }
  .order-item-content {
    .order-time {
      margin-top: 6px;
      color: rgba(23, 23, 23, 0.4);
    }
    .color-opacity-65 {
      opacity: 0.65;
    }
    .label {
      margin-right: 4px;
    }
    .order-statu {
      color: #ff6f00;
    }
    .finish-color {
      color: rgba(23, 23, 23, 0.4);
    }
  }
  .order-item-content:last-child {
    border: none;
  }
  .goods-content {
    .goods-image {
      width: 70px;
      height: 70px;
    }
    .goods-detail-line {
      margin-bottom: 4px;
    }
    .goods-sum-icon {
      margin-right: 1px;
    }
  }
}
</style>
