<template>
  <van-tabs
    class="subject-tabs flex flex-direction-column"
    v-model="reacData.selectedSubject"
    swipe-threshold="1"
    @click="onSelectSubject"
  >
    <van-tab
      v-for="(item, index) in props.subjectList"
      :key="item.subject_data_id"
    >
      <template #title>
        <div
          class="subject-tab flex flex-align-center relative"
          :class="{
            ml8: index === 0,
            mr8: index === props.subjectList.length - 1
          }"
        >
          <img
            class="subject-icon"
            :src="
              require(`@/assets/img/customer-portrait/${item.tag_platform_type}.svg`)
            "
          />
          {{ item.name }}
          <div
            v-show="reacData.selectedSubject === item.subject_data_id"
            class="active-border"
          />
        </div>
      </template>
      <component
        :key="item.subject_data_id"
        :is="componentEnum[item.tag_platform_type] || otherComponent"
        :dataSource="item"
        :visible="reacData.selectedSubject === item.subject_data_id"
        :shopType="reacData.shopType"
      />
    </van-tab>
  </van-tabs>
</template>

<script setup>
import { onMounted, watch, reactive, ref } from 'vue'
import {
  tabsList,
  tabsMapping,
  portraitImgs,
  sexIcons,
  orderStatuEnum
} from '../portrait'
import ThirdView from './third.vue'
import YouzanView from './youzan.vue'
import OtherView from './other.vue'
import BusinessView from './business.vue'

const props = defineProps({
  dataSource: {
    type: Object,
    default: () => ({})
  },
  // 用户id
  userId: {
    type: String,
    default: () => ''
  },
  // 主体列表
  subjectList: {
    type: Array,
    default: () => []
  },
  // 是否是商城业务主体ID
  orderSubjectId: {
    type: String,
    default: () => ''
  }
})

const componentEnum = {
  THIRD: ThirdView,
  YOUZAN: YouzanView,
  BUSINESS: BusinessView
} // 主体类型对应组件
const otherComponent = OtherView // 其他主体类型组件
const reacData = reactive({
  subjectList: [], // 主体列表
  selectedOrderStatu: ref('all'), // 当前选中的订单状态
  selectedSubject: 0, // 当前选中的主体
  shopType: false // 当前选中的主体是否具有商城订单
})

onMounted(() => {
  initData()
})

watch(
  () => props.subjectList,
  (newval, oldval) => {
    console.log('!!!!!', newval.length)
    if (newval !== oldval && newval.length) {
      reacData.selectedSubject = newval[0].subject_data_id
    }
  }
)

const initData = async () => {
  try {
  } catch (error) {
    console.log(error)
  }
}

// 切换主体
const onSelectSubject = (index) => {
  reacData.selectedSubject = props.subjectList[index].subject_data_id
  if (props.subjectList[index].subject_id == props.orderSubjectId) {
    reacData.shopType = true
  } else {
    reacData.shopType = false
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
