<template>
  <div class="calendar-box">
    <div class="calendar-op flex flex-align-center flex-justify-center mb5">
      <icon name="arrow-left" @click="arrowLeft"></icon>
      <span class="plr5">{{ data.nowYear }}年{{ data.nowMonth + 1 }}</span>
      <icon name="arrow" @click="arrowRight"></icon>
    </div>
    <calendar
      ref="calendarRef"
      v-model:show="data.show"
      :poppable="false"
      :default-date="null"
      :min-date="data.minDate"
      :max-date="data.maxDate"
      :show-confirm="false"
      :formatter="formatterDay"
      :show-mark="false"
      :show-title="false"
      :show-subtitle="false"
      :row-height="42"
      color="#1773FA"
      @select="selectDay"
    />
  </div>
</template>

<script setup>
import { Calendar, Icon } from 'vant'
import { reactive, getCurrentInstance, ref, nextTick } from 'vue'
import { formatDate } from '@/utils/index.js'
import { getCustomerDayList } from '@/service/customer.service.js'
const { proxy } = getCurrentInstance()
const props = defineProps({
  ywId: {
    type: String,
    required: true
  }
})
// const emits = defineEmits(['selectDate'])
const calendarRef = ref(null)
const data = reactive({
  show: true,
  minDate: new Date(),
  maxDate: new Date(),
  nowYear: new Date().getFullYear(),
  nowMonth: new Date().getMonth(),
  dayList: {},
  selectedDay: ''
})
const formatterDay = (day) => {
  if (!data.dayList[day.text]) {
    day.type = 'disabled'
  }
  return day
}
const selectDay = (val) => {
  let date = formatDate(val, 'yyyy-MM-dd')
  //   emits('selectDate', date)
  if (data.selectedDay === date) {
    data.selectedDay = ''
    calendarRef.value.reset()
    proxy.$emit('selectDate', '')
  } else {
    data.selectedDay = date
    proxy.$emit('selectDate', date)
  }
  //   proxy._.emit('selectDate', date)
}
console.log(proxy)
// 当前月上一个月
const arrowLeft = () => {
  data.nowMonth--
  setMinMaxDay()
}

const arrowRight = () => {
  data.nowMonth++
  setMinMaxDay()
}
const getData = async (nowYear, nowMonth) => {
  let month = nowMonth + 1
  let params = {
    day_time: `${nowYear}-${month < 10 ? '0' + month : month}`,
    yw_id: props.ywId
  }
  try {
    const { code, data: dayListObj } = await getCustomerDayList(params)
    if (code == 200) {
      for (const key in dayListObj) {
        if (Object.hasOwnProperty.call(dayListObj, key)) {
          let dayKey = Number(key.split('-')[2])
          data.dayList[dayKey] = dayListObj[key]
        }
      }
    }
  } catch (error) {}
}
// 设置显示月份可选择的天数区间
const setMinMaxDay = () => {
  if (data.nowMonth === -1) {
    data.nowYear--
    data.nowMonth = 11
  }
  if (data.nowMonth === 12) {
    data.nowMonth = 0
    data.nowYear++
  }
  data.minDate = new Date(data.nowYear, data.nowMonth, 1)
  data.maxDate = new Date(data.nowYear, data.nowMonth + 1, 0)
  getData(data.nowYear, data.nowMonth)
}
setMinMaxDay()
</script>
<style lang="less" scoped>
.calendar-box {
  :deep(.van-calendar__selected-day) {
    border-radius: 50%;
    width: 32px !important;
    height: 32px !important;
  }
  :deep(.van-calendar__month-title) {
    display: none;
  }
  :deep(.van-calendar__header) {
    box-shadow: none;
    height: 32px;
    background: #f9fafb;
    box-shadow: 0px 1px 1px 0px #edeef2;
  }
  :deep(.van-calendar__day) {
    height: 34px !important;
  }
  .calendar-op {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 22px;
  }
}
</style>
