<template>
  <div class="container-label relative pbs">
    <van-cell-group :border="false" class="user-label">
      <van-cell center>
        <template #title>
          <div class="cl-tag-title">
            <span class="user-title1">个性特点</span>
            <span class="user-title2">让你更了解客户，为你创造更大的价值</span>
          </div>
        </template>
      </van-cell>
      <van-cell v-if="!reacData.oneselfLabel.length">
        <template #title>
          <van-empty
            :image="props.selectedData.emmptyImg"
            :description="props.selectedData.emptyText"
          >
            <van-button
              type="info"
              class="bottom-add-icon"
              @click="onAddHandle"
            >
              去添加
            </van-button>
          </van-empty>
        </template>
      </van-cell>
      <van-cell v-else>
        <template #title>
          <div class="label-box-content">
            <div
              v-for="tag in reacData.oneselfLabel"
              :key="tag"
              class="tag-box-content"
            >
              <div
                :class="[
                  'label-item',
                  tag.tag_type == 'HQ' ? 'la-headquarters' : 'la-service'
                ]"
              >
                <div class="label-title">
                  {{ tag.group_name }}
                </div>
                <div class="label-value">
                  {{ tag.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="label-line"></div>
          <div>
            <div class="other-label-test">其他成员标记的标签</div>
            <div class="label-box-content">
              <div
                v-for="tag in reacData.otherLabel"
                :key="tag"
                class="tag-box-content"
              >
                <div
                  :class="[
                    'label-item',
                    tag.tag_type == 'HQ' ? 'la-headquarters' : 'la-service'
                  ]"
                >
                  <div class="label-title">
                    {{ tag.group_name }}
                  </div>
                  <div class="label-value">
                    {{ tag.name }}
                    <div class="label-circle">
                      <div class="label-num">
                        {{ tag.count }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-cell>
      <!-- Empty -->
    </van-cell-group>
    <img
      :src="portraitImgs.addIcon"
      class="add-icon"
      @click="onAddHandle"
      v-show="reacData.oneselfLabel.length"
    />
  </div>
</template>

<script setup>
import { getCustomerLabeleds } from '@/service/customer.service.js'
import { onMounted, reactive } from 'vue'
import { portraitImgs } from './portrait'
import { useRouter } from 'vue-router'

const props = defineProps({
  // 用户id
  userId: {
    type: String,
    default: () => ''
  },
  selectedData: {
    type: Object,
    default: () => ({})
  }
})

const router = useRouter()
const reacData = reactive({
  oneselfLabel: [], // 个人标签
  otherLabel: [] // 其他成员标签
})

onMounted(() => {
  getLabelData()
})
// 获取标签数据
const getLabelData = async () => {
  try {
    const res = await getCustomerLabeleds(props.userId)
    if (res && res.code == 200) {
      reacData.oneselfLabel = [...res.data.hq, ...res.data.my_tag]
      reacData.otherLabel = res.data.other_tag
      console.log(reacData.oneselfLabel, reacData.otherLabel)
    }
  } catch (error) {
    console.log(error)
  }
}
// 打标签
const onAddHandle = async (key) => {
  router.push({
    name: props.selectedData.path,
    query: { id: props.userId }
  })
}
</script>

<style lang="less" type="text/less" scoped>
.cl-tag-title {
  display: flex;
  flex-direction: column;
  .user-title1 {
    font-size: 16px;
    font-weight: bold;
    color: #171717;
  }
  .user-title2 {
    font-size: 12px;
    color: rgba(23, 23, 23, 0.6);
  }
}
.bottom-add-icon {
  width: 90px;
  height: 34px;
  color: #fff;
  border: none;
  font-size: 14px;
  line-height: 34px;
  border-radius: 6px;
  background: #1773fa;
}
.label-box-content {
  display: flex;
  flex-wrap: wrap;
  .tag-box-content {
    display: flex;
    padding-top: 6px;
    //   flex-wrap: nowrap;
    align-items: center;

    .label-item {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      line-height: 16px;
      align-items: center;
      font-size: 12px;
      border-radius: 2px;
      max-height: 54px;
      margin-right: 8px;
      margin-top: 9px;
      height: 100%;
      position: relative;

      .label-title {
        // flex: 1;
        padding: 2px 4px;
        max-width: 128px;
        // border-radius: 2px 0px 0px 2px;
        // white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .label-value {
        flex: 1;
        color: #fff;
        padding: 2px 8px;
        //   max-width: 136px;
        // position: relative;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .label-circle {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        background: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 1px 1px 0px #ff6f00;
        .label-num {
          font-size: 11px;
          font-weight: 600;
          color: #ff8c33;
          line-height: 22px;
        }
      }
    }

    // 总部标签
    .la-headquarters {
      border: 1px solid #1773fa;
      .label-title {
        color: #1773fa;
        // border: 1px solid #1773fa;
      }
      .label-value {
        background-color: #1773fa;
        // border: 1px solid #1773fa;
      }
    }
    //服务标签
    .la-service {
      border: 1px solid #ff6f00;
      .label-title {
        color: #ff6f00;
        // border: 1px solid #ff6f00;
      }
      .label-value {
        background-color: #ff6f00;
        // border: 1px solid #ff6f00;
      }
    }
  }
}
.label-line {
  margin-top: 16px;
  height: 1px;
  width: 100%;
  border: 1px dashed #c4c4c4;
}
.other-label-test {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  line-height: 20px;
}
.customer-follow {
  position: relative;
  width: 100%;
  //   padding: 24px 14px;
  box-sizing: border-box;
  background-color: #fff;
  .type {
    background-color: #fff;
  }
  :deep(.van-field) {
    height: 114px;
    // padding: 12px 11px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #edeef2;
    .van-field__body {
      height: 85%;
    }
    .van-field__control {
      height: 85% !important;
      margin-top: -20px !important;
    }
    .van-field__word-limit {
      width: 76%;
      margin-top: -7px;
    }
  }
  .save-btn {
    position: absolute;
    top: 62px;
    right: -6px;
    width: 74px;
    height: 32px;
    line-height: 32px;
    // margin: 18px 0 0;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    background: #1773fa;
    border-radius: 6px;
  }
  .blur-once {
    position: relative;
    width: 100%;
    height: 114px;
    background: #edeff2;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    .blur-text {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      padding-left: 12px;
      padding-top: 12px;
    }
    .img-class {
      position: absolute;
      top: 72px;
      right: 8px;
    }
  }
}
.add-icon {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 60px;
  right: 15px;
}
</style>
