<template>
  <div class="container-dynamic relative pbs">
    <van-cell-group :border="false">
      <van-cell class="dynamic-select">
        <van-dropdown-menu active-color="#1773FA">
          <van-dropdown-item
            ref="dropdownCalendar"
            :title="reacData.selectedCalendar || '日历'"
          >
            <ShowCalendar
              :yw-id="props.userId"
              @selectDate="(time) => seachHandle('time', time)"
            />
          </van-dropdown-item>
          <van-dropdown-item
            ref="dropdownSubject"
            :title="reacData.selectedType.name || '客户类型'"
          >
            <div
              v-for="item in reacData.subjectList"
              :key="item.id"
              class="flex-1 p6 bgf mb5"
              @click="seachHandle('subject', item)"
            >
              <div
                :class="[
                  'flex flex-justify-center flex-align-center flex-g1 flex-s1 subjects-key-item',
                  reacData.selectedType.id === item.id
                    ? 'subjects-key-item-select'
                    : ''
                ]"
              >
                <img
                  :src="
                    require(`../../../assets/img/customer-portrait/${item.tag_platform_type}.svg`)
                  "
                  alt=""
                  class="subjects-key-img"
                />
                <div class="subjects-key-name">{{ item.name }}</div>
              </div>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            ref="dropdownDynamic"
            :title="reacData.selectedDynamic.key || '动态分组'"
          >
            <van-cell
              v-for="item in reacData.dynamicTypes"
              :key="item.value"
              @click="seachHandle('dynamic', item)"
            >
              <template #title>
                <span
                  :class="[
                    item.value === reacData.selectedDynamic.value
                      ? 'dynamic-type-title-select'
                      : 'dynamic-type-title'
                  ]"
                >
                  {{ item.key }}
                </span>
              </template>
              <template #right-icon>
                <img
                  v-if="item.value === reacData.selectedDynamic.value"
                  :src="checkIcon"
                  alt=""
                />
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-cell>
      <van-cell>
        <template #title>
          <van-list
            v-model:loading="reacData.loading"
            :finished="reacData.isFinished"
            :finished-text="
              reacData.dynamicList.length === 0
                ? '当前客户暂无动态'
                : '没有更多了'
            "
            :immediate-check="false"
            @load="getDynamicList"
          >
            <div
              class="logs-item"
              v-for="(item, idx) in reacData.dynamicList"
              :key="idx"
            >
              <div class="item-date">
                <div class="item-date-content">
                  {{ item.time }}
                </div>
              </div>
              <template v-for="dynamic in item.list" :key="dynamic.id">
                <DynamicList :dynamic-obj="dynamic" dynamic-type="customer" />
              </template>
              <div class="create-time" v-if="item.createTime">
                {{ item.create_time }}
              </div>
            </div>
          </van-list>
          <!-- 如果不存在 -->
        </template>
      </van-cell>
    </van-cell-group>
    <img
      :src="portraitImgs.addDynamic"
      class="add-icon"
      @click="onAddHandle"
      v-show="props.isNormal && reacData.dynamicList.length"
    />
  </div>
</template>

<script setup>
import {
  getCustomerNews,
  getSubjectsKey,
  getDynamicType
} from '@/service/customer.service.js'
import { onMounted, reactive, ref, watch } from 'vue'
import { portraitImgs } from './portrait'
import { useRouter } from 'vue-router'
import ShowCalendar from '../../community/components/ShowCalendar.vue'
import DynamicList from '../../community/components/DynamicList.vue'
import { ActionSheet } from 'vant'
import checkIcon from '@/assets/img/customer-portrait/check.svg'

const props = defineProps({
  // 用户id
  userId: {
    type: String,
    default: () => ''
  },
  selectedData: {
    type: Object,
    default: () => ({})
  },
  refresh: {
    type: Boolean,
    default: () => false
  },
  isNormal: {
    type: Boolean,
    default: () => false
  }
})

const router = useRouter()
const dropdownCalendar = ref(null)
const dropdownSubject = ref(null)
const dropdownDynamic = ref(null)
const holdBack = ref(false)
const reacData = reactive({
  subjectList: [], // 主体列表(客户类型)
  dynamicTypes: [], // 动态类型
  currentPage: 0, // 当前页面
  pageSize: 10, // 每页的数量
  selectedCalendar: '', // 选中的日历时间
  selectedType: {}, // 选中的客户类型
  selectedDynamic: {}, // 选中的动态分组
  dynamicList: [], // 动态列表
  loading: false, // 列表加载状态
  isFinished: false // 列表是否加载完成
})

// 聊天工具栏提交用户语录时刷新用户动态
watch(
  () => props.refresh,
  (newval) => {
    initData()
  }
)
onMounted(() => {
  initData()
})
// 筛选条件
const seachHandle = (key, data) => {
  const { selectedType, selectedDynamic, selectedCalendar } = reacData

  switch (key) {
    case 'subject':
      reacData.selectedType = selectedType.id === data.id ? {} : data
      dropdownSubject.value.toggle(false)
      break
    case 'dynamic':
      reacData.selectedDynamic =
        selectedDynamic.value === data.value ? {} : data
      dropdownDynamic.value.toggle(false)
      break
    case 'time':
      reacData.selectedCalendar = selectedCalendar === data ? '' : data
      dropdownCalendar.value.toggle(false)
      break
    default:
      break
  }
  reacData.currentPage = 0
  reacData.isFinished = false
  reacData.dynamicList = []

  getDynamicList()
}

const getDynamicList = async () => {
  try {
    // 阻止重复请求
    if (holdBack.value) {
      return false
    } else {
      holdBack.value = true
    }

    const {
      dynamicList,
      currentPage,
      pageSize,
      selectedType,
      selectedDynamic,
      selectedCalendar
    } = reacData

    const body = {
      page: currentPage + 1,
      limit: pageSize,
      show_type: 2,
      yw_id: props.userId
    }
    // subject_data_id: props.userId,
    if (selectedType) body.subject_id = selectedType.id
    if (selectedDynamic) body.type = selectedDynamic.value
    if (selectedCalendar) {
      body.start_time = selectedCalendar
      body.end_time = selectedCalendar
    }

    const { data } = await getCustomerNews(body)
    reacData.loading = false
    holdBack.value = false
    if (data && data.data.length) {
      reacData.currentPage++
      const list = [...dynamicList]
      if (list[list.length - 1]?.time === data.data[0]?.time) {
        list[list.length - 1]?.list.push(...data.data[0]?.list)
      } else {
        list.push(...data.data)
      }
      reacData.dynamicList = [...list]
    } else {
      reacData.isFinished = true
    }
  } catch (error) {
    console.log(error)
  }
}
// 初始化
const initData = async () => {
  try {
    const [{ data: dynamicTypes }, { data: subjectsKey }] = await Promise.all([
      getDynamicType(),
      getSubjectsKey(props.userId)
    ])

    reacData.currentPage = 0
    reacData.dynamicList = []
    reacData.isFinished = false
    reacData.dynamicTypes = dynamicTypes
    reacData.subjectList = subjectsKey.subject_list
    console.log('初始化请求', reacData)
    getDynamicList()
  } catch (error) {
    console.log(error)
  }
}
// 打标签
const onAddHandle = async (key) => {
  router.push({
    name: props.selectedData.path,
    params: { id: props.userId, type: 'portraut' }
  })
}
</script>

<style lang="less" type="text/less" scoped>
.container-dynamic {
  :deep(.van-dropdown-menu) {
    .van-dropdown-item__content {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 15px;
    }
  }
  :deep(.dynamic-select.van-cell) {
    padding: 0 16px;
  }
}
.subjects-key-item {
  width: 168px;
  height: 40px;
  background: #f8fbff;
  border-radius: 2px;
}
.subjects-key-item-select {
  border: 1px solid #1773fa;
}
.subjects-key-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #171717;
}
.subjects-key-img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.dynamic-type-title {
  font-size: 14px;
  font-weight: 400;
  color: #171717;
}
.dynamic-type-title-select {
  font-size: 14px;
  font-weight: 500;
  color: #1773fa;
}
.add-icon {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 60px;
  right: 15px;
}
</style>
