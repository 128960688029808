<template>
  <div class="container bgf fs14 flex flex-direction-column">
    <div class="container-header pl15 pr15 flex-s0">
      <!-- 工作台 - 用户信息 -->
      <div
        v-if="state.isNormal"
        class="user-info-content mb8 flex flex-align-center"
      >
        <img
          class="user-avater mr8 flex-s0"
          :src="state.portrait.pic || defaultAvatar"
        />
        <div class="user-info flex-1 txt-line-1">
          <div class="user-info-top flex flex-align-center">
            <span class="fs16 fw500 txt-line-1">
              {{ state.portrait.note }}
            </span>
            <span
              v-if="state.portrait.qw_type == 1 || state.portrait.qw_corp_name"
              class="user-corp-name flex-s0"
              :class="{ 'user-corp-wechat': state.portrait.qw_type == 1 }"
            >
              {{
                state.portrait.qw_type == 1
                  ? '@微信'
                  : `@${state.portrait.qw_corp_name}`
              }}
            </span>
            <img
              class="user-sex-icon flex-s0 mr8"
              :src="getUserSexIcon(state.portrait.sex)"
            />
          </div>
          <div class="user-info-buttom fs12 txt-line-1">
            昵称：{{ state.portrait.yw_name || '--' }}
          </div>
        </div>
        <div
          class="user-relation tc relative fw500 txt-blue flex-s0"
          @click="onRelation"
        >
          <img class="icon" :src="portraitImgs.sendMsg" />
          发消息
        </div>
      </div>
      <!-- 聊天工具栏 - 备注用户记录 -->
      <div v-else class="user-record-write relative">
        <van-field
          v-model="state.content"
          autosize
          type="textarea"
          maxlength="800"
          placeholder="记录用户所说的重点！"
        />
        <div
          v-if="!state.clickFouce"
          class="cover-layer"
          @click.stop="state.clickFouce = true"
        />
        <div
          v-if="state.clickFouce"
          class="user-record-handle flex flex-align-center"
        >
          <span class="fs12 cl99 mr8">{{ `${state.content.length}/800` }}</span>
          <van-button type="primary" @click="onSubmit" class="submit-btn">
            确认
          </van-button>
        </div>
        <div v-else class="user-record-handle">
          <img class="write-icon" :src="portraitImgs.writeIcon" />
        </div>
      </div>
    </div>
    <div class="container-body flex-1">
      <van-tabs
        class="nav-main"
        line-width="0"
        v-model="state.activeTab"
        @click="onClickTabItem"
      >
        <van-tab
          title-class="nav-main-tab"
          :key="index"
          v-for="(item, name, index) in tabsList"
          :name="item.type"
        >
          <template #title>
            <div
              class="tab-title flex flex-direction-column flex-align-center flex-justify-center"
              :class="{
                'nav-main-tab-active': item.type == state.activeTab
              }"
              :style="{
                'background-image': `url(${
                  item.type == state.activeTab
                    ? portraitImgs.select
                    : portraitImgs.unselect
                })`
              }"
            >
              <div class="nav-main-tab-title fw500">{{ item.title }}</div>
              <div class="nav-main-tab-text fs12">{{ item.secondTitle }}</div>
            </div>
          </template>
          <component
            :is="componentEnum[item.type]"
            :selectedData="item"
            :subjectList="state.subjectList"
            :userId="state.external_userid"
            :refresh="state.refreshDynamic"
            :isNormal="state.isNormal"
            :orderSubjectId="state.portrait.order_subject_id"
          />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import {
  getCurExternalContact,
  openEnterpriseChat,
  createCorpGroupChat
} from '@/utils/nwechat.js'
import { defaultAvatar } from '@/utils/constants'
import { Toast } from 'vant'
import { addFlollow, getShoppersDetail } from '@/service/customer.service.js'
import { getUserSexIcon } from '@/utils/index'
import { tabsList, portraitImgs } from './portrait'
import DetailView from './detail'
import LabelView from './label.vue'
import dynamicView from './dynamic.vue'

const route = useRoute()
const componentRef = ref(null)
const componentEnum = { data: DetailView, tag: LabelView, trends: dynamicView }
const state = reactive({
  portrait: {},
  external_userid: '',
  activeTab: tabsList.data?.type,
  content: '',
  clickFouce: false,
  isNormal: true, // 是否工作台入口
  subjectList: [], // 主体列表
  refreshDynamic: false
})

//打开会话
const onRelation = () => {
  let obj = {
    externalUserIds: state.external_userid
  }
  console.log(obj)
  if (
    localStorage.getItem('chain_corp_id') &&
    localStorage.getItem('chain_corp_id') != '0'
  ) {
    console.log('createCorpGroupChatparams:', obj)
    createCorpGroupChat(obj)
  } else {
    obj.ChatId = ''
    openEnterpriseChat(obj)
  }
}
//判断是否工具栏入口
const enterType = async () => {
  let external_userid = route.query.external_userid
  if (external_userid) {
    state.isNormal = true
    initData(external_userid)
  } else {
    state.isNormal = false
    let extend_userid = await getCurExternalContact()
    initData(extend_userid)
  }
}
//初始化
const initData = async (external_userid) => {
  state.external_userid = external_userid
  try {
    const [{ data: shoppersDetail }] = await Promise.all([
      getShoppersDetail(external_userid)
    ])
    state.portrait = shoppersDetail.info
    state.subjectList = shoppersDetail.subject_data_list
  } catch (error) {
    console.log(error)
  }
}

// tab栏切换;
const onClickTabItem = async (name, title) => {
  state.activeTab = name
}
//提交跟进记录
const onSubmit = async () => {
  try {
    if (state.content.length === 0) {
      return Toast('请记录用户所说的重点！')
    }
    await addFlollow({
      external_userid: state.portrait.yw_id,
      followup_type_content: state.content
    })
    Toast.success('添加成功！')
    state.content = ''
    state.clickFouce = false
    state.refreshDynamic = !state.refreshDynamic
  } catch (error) {
    Toast.fail('添加失败！')
  }
}

onMounted(async () => {
  await enterType()
})
onUnmounted(() => {})
</script>

<style lang="less" type="text/less" scoped>
@import './portrait.less';
@import '../../../assets/style/theme.less';
</style>
