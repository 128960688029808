// tabsMapping
export const tabsMapping = {
  data: 'data',
  tag: 'tag',
  trends: 'trends'
}
export const tabsList = {
  [tabsMapping.data]: {
    title: '客户资料',
    secondTitle: '了解基本信息',
    type: tabsMapping.data,
    emptyText: '暂无客户资料'
  }, // 客户资料
  [tabsMapping.tag]: {
    title: '客户标签',
    secondTitle: '了解客户特性',
    type: tabsMapping.tag,
    path: 'customer-tag',
    emptyText: '暂无标签，快给客户添加标签吧~',
    emmptyImg: require('@/assets/img/customer-portrait/no-data.png')
  }, //客户标签
  [tabsMapping.trends]: {
    title: '客户动态',
    secondTitle: '实时掌控动态',
    type: tabsMapping.trends,
    path: 'customer-follow',
    emptyText: '暂无暂无客户资料'
  } // 客户动态;
}

export const portraitImgs = {
  select: require('@/assets/img/customer-portrait/selected.png'), // 选中时样式,
  unselect: require('@/assets/img/customer-portrait/no-select.png'), // 未选中图片;
  add: require('@/assets/img/customer-portrait/add.png'), // 未选中图片;
  addIcon: require('@/assets/img/customer-portrait/addicon.svg'), // 未选中图片;
  addDynamic: require('@/assets/img/group-portrait/writing-fluently-background.svg'), // 添加动态icon;
  sendMsg: require('@/assets/img/customer-portrait/send-msg.png'), // 发消息图标
  writeIcon: require('@/assets/img/customer-portrait/writing-fluently.svg') // 书写图标
}
// <!-- sex=0未知，1男，2女 -->
export const sexIcons = {
  1: require('@/assets/img/common/man.png'),
  2: require('@/assets/img/common/women.png')
}
// 订单状态
export const orderStatuEnum = [
  { key: 'all', value: '全部' },
  { key: 'WAIT_BUYER_PAY', value: '待付款' },
  { key: 'TRADE_PAID', value: '已付款' },
  { key: 'WAIT_CONFIRM', value: '待接单' },
  { key: 'WAIT_SELLER_SEND_GOODS', value: '待发货' },
  { key: 'WAIT_BUYER_CONFIRM_GOODS', value: '已发货' },
  { key: 'TRADE_SUCCESS', value: '已完成' },
  { key: 'TRADE_CLOSED', value: '已关闭' }
]
// 订单类型
export const orderType = [
  { key: 'store', value: '到店订单' },
  { key: 'express', value: '快递订单' },
  { key: 'coupon', value: '优惠券' }
]
